import React from 'react'

function AdhocsTable() {
  return (
    <div>
        <table cellspacing="0" border="1">
						<tbody>
							<tr className="bg-zinc-700 text-white">
								<td className='min-w-20 font-bold p-2'>Additional Costs</td>
								<td className='min-w-20 font-bold p-2'>Cost per search</td>
								<td className='min-w-20 font-bold p-2'></td>
								<td className='min-w-20 font-bold p-2'></td>
								<td className='min-w-20 font-bold p-2'>Description / Conditions / Turn-around time </td>
								<td className='min-w-20 font-bold p-2'></td>
								<td className='min-w-20 font-bold p-2'></td>
								<td className='min-w-20 font-bold p-2'></td>
								<td className='min-w-20 font-bold p-2'></td>
								<td className='min-w-20 font-bold p-2'>Exclusions</td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'>Criminal Checks (AFIS)</td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 338.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>5 Business Hours</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>All inclusive </td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 416.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>2 Business days</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'>Qualification Verifications</td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 59.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>Matric Pre ‘92 10 x business days</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>Excluded hard costs (limited to qualification verifications – higher education institutions which differs from institution to institution). *Does not include institution fees which are billed separately. </td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 59.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>Matric Post ‘92 1 x business day</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 59.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>National Tertiary Qualification* -per qualification</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 59.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>Short Courses* - per course</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 59.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>Qualification Memberships*</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 59.00</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>Institution Accreditation*</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
							</tr>
							<tr>
								<td className='min-w-20 border border-neutral-300 p-2'>Driver&#39;s licenses - eNatis </td>
								<td className='min-w-20 border border-neutral-300 p-2'>R 103.60</td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'>2 x business days </td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
								<td className='min-w-20 border border-neutral-300 p-2'></td>
							</tr>
						</tbody>
					</table>
    </div>
  )
}

export default AdhocsTable