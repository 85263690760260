import React from 'react'

// Cards
import Header from '../../../components/cards/header/header';

function Directory() {
  return (
    <div>
        <Header/>

        Directory
    </div>
  )
}

export default Directory