import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/dx.generic.devextreme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { UrlString } from '@azure/msal-common';

// Sentry.io
import * as Sentry from "@sentry/react";

import Context from './context/Context';

//Sentry.io
Sentry.init({
  dsn: "https://d9230fcc12ca4a06a62513358f3505aa@o4504909803618304.ingest.sentry.io/4504909818036224",
  environment: "production",
  tracesSampleRate: 0.1,
});

const msalInstance = new PublicClientApplication({
auth: {
  clientId: 'a3a029c4-7b43-4ffb-b531-2df131ab3579',
  authority: 'https://login.microsoftonline.com/85621cbe-e879-408d-9d24-4944bff5669e',
  redirectUri: '/',
}
});


/**
 * We need to determine if we are in the auth popup
 * If so, we need to render a simple message
 * Otherwise, we render the app
 * [MSAL uses]{@link (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/interaction_client/PopupClient.ts#L343)} "UrlString.hashContainsKnownProperties" method to validate the hash
 * @returns true if the current window is an auth popup
 */
const isAuthPopup = () => {
  const hash = window.location.hash;
  return UrlString.hashContainsKnownProperties(hash);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

// check if we are in the auth popup
// if so, render a simple message
// otherwise, render the app
if (isAuthPopup()) {
  root.render(<div>
    <h1>Authenticating...</h1>
  </div>);
} else {
  root.render(
    <React.StrictMode>
      <Context>
        <App  msalInstance={msalInstance}/>
      </Context>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
